




























import { Observer } from 'mobx-vue'
import { Component, Vue } from 'vue-property-decorator'

@Observer
@Component({
  components: {
    'referral-dao-earned': () => import('@/modules/referral/components/referral-dao-earned.vue'),
    'referral-recent-dao': () => import('@/modules/referral/components/referral-recent-dao.vue'),
  },
})
export default class extends Vue {}
